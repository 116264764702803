<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <outstanding-credit-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <!-- <div class="tile is-parent animated lightSpeedIn" v-show="toggle"> -->
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(outstandingcredit, index) in outstandingcreditSummaries"
                :key="outstandingcredit.invoiceRef"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>
                  <a @click="edit(outstandingcredit.creditReturnMainID)">{{ outstandingcredit.invoiceRef }}</a>
                </td>
                <td>{{ outstandingcredit.creditorName }}</td>
                <td>{{ $filters.formatDateTimezone(outstandingcredit.invoiceDate, $userInfo.locale) }}</td>
                <td class="has-text-right">{{ outstandingcredit.balance | formatNumberWithRounding($userInfo.locale) }}</td>
                <td class="has-text-right">
                  <span>{{ outstandingcredit.totalAmount | formatNumberWithRounding($userInfo.locale) }}</span>
                </td>
                <td class="has-text-right">
                  <span>{{ outstandingcredit.gstAmount | formatNumberWithRounding($userInfo.locale) }}</span>
                </td>
                <td class="has-text-right">
                  <span>{{ outstandingcredit.amount | formatNumberWithRounding($userInfo.locale) }}</span>
                </td>
                <td class="has-text-centered">
                  <span>{{ outstandingcredit.purchaseOrderNo }}</span>
                </td>
                <td class="has-text-centered">
                  <a class="button is-primary is-small is-inverted tooltip"
                    @click="edit(outstandingcredit.creditReturnMainID)"
                    data-tooltip="Click to view">
                    <span class="icon is-medium">
                      <i class="mdi mdi-file-document mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import OutstandingCreditListSideMenu from './OutstandingCreditListSideMenu'
import ReturnPartsRoutes from '@/views/returnparts/route-types'
import OutstandingCreditService from './OutstandingCreditService'
// import Guid from '@/components/Guid'
import { EventHubTypes, AppHeaderButtonTypes } from '@/enums'
import BulmaTable from '@/components/BulmaTable'
import { Columns } from './columns'
import StoreMixin from './storeMixin'
// import { InsurerPayload } from '@/classes'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { KeyValuePairModel } from '@/classes/viewmodels'
// import _transform from 'lodash/transform'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import { Emailer } from '@/classes'
import { NumberFiltersMixin } from '@/components/mixins/filters'

export default {
  name: 'OutstandingCreditListView',
  components: {
    AppDetailHeader,
    OutstandingCreditListSideMenu,
    BulmaTable
  },
  mixins: [DateTimeFiltersMixin, StoreMixin, NumberFiltersMixin],
  data() {
    return {
      totalRows: 0,
      outstandingcreditSummaries: {},
      accessRoutes: [],
      filter: {
        InvoiceReturnNo: '',
        Vendor: '',
        PurchaseOrderNo: '',
        DateFrom: '',
        DateTo: '',
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      toggle: true,
      selectedRow: null,
      isTableLoading: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  created() {
    // OutstandingCreditService.clearPreviousSessionStorage(this.$userInfo.sessionId)
    this.getData()
    this.$eventHub.$on(EventHubTypes.ToggleSideMenu, (data) => {
      this.toggle = false
      setTimeout(() => {
        this.toggle = true
      }, 200)
    })
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addStoreItem(new InsurerPayload())
          break
        case AppHeaderButtonTypes.Print:
          this.print1()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    async getData() {
      this.$showSpinner()
      this.isTableLoading = true
      this.retrieveFilter()
      this.outstandingcreditSummaries = await OutstandingCreditService.getEntitySummaries(this.filter)
      this.totalRows = this.outstandingcreditSummaries.length > 0 ? this.outstandingcreditSummaries[0].totalRows : 0
      this.isTableLoading = false
      // console.log(this.insurerSummaries)
      this.$hideSpinner()
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getData()
    },
    generateUrl(id) {
      return `outstandingcredit/${id}`
    },
    gotoV1(creditReturnMainID, invoiceRef) {
      window.open(
        `${process.env.VUE_APP_ROOT_URI}/Modules/ReturnPartsView/ReturnParts.aspx?RPId=${creditReturnMainID}&RedirectUrl=/uniq/v2/outstandingcredit`,
        '_self'
      )
    },
    edit(id, event) {
      this.setReturnPartsReturnRoute(this.$route)
      this.$router.push({
        name: ReturnPartsRoutes.ReturnPartsDetail.name,
        params: { creditReturnMainId: id }
      })
    },
    print1() {
      let dateFrom = this.filter.DateFrom
      let dateTo = this.filter.DateTo
      const params = {
        CompanyID: this.$userInfo.companyId,
        COMPANYNAME: this.$userInfo.companyName,
        REPORTTITLE: 'OUTSTANDING CREDITS',
        VendorName: this.filter.Vendor === '' ? '' : this.filter.Vendor,
        InvoiceNo: this.filter.InvoiceReturnNo === '' ? '' : this.filter.InvoiceReturnNo,
        PurchaseOrderNo: this.filter.PurchaseOrderNo === '' ? '' : this.filter.PurchaseOrderNo,
        FromDate: dateFrom ,
        ToDate: dateTo,
        SortBy: this.filter.sortColumn,
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      }

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.subject = 'Otstanding Credit Report'
      emailer.reportName = 'rptOutstandingCreditList'
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    filterList() {
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getData()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getData()
    },
    resetList() {
      this.filter.InvoiceReturnNo = ''
      this.filter.Vendor = ''
      this.filter.PurchaseOrderNo = ''
      this.filter.DateFrom = ''
      this.filter.DateTo = ''
      this.filter.sortColumn = Columns[0].name
      this.filter.sortOrder = Columns[0].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.persistFilter()
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.zoomIn {
  animation: zoomIn 0.5s;
}
</style>
